.contact-info {
  padding: 3rem 10%;
  max-width: 1440px;
  width: 100%;
  position: relative;
  color: $color-warm-white;
  z-index: 0;

  // Add responsive styles for smaller devices
  @include tablet() {
    padding: 2rem 50px;
  }

  @include mobile() {
    padding: 1rem 30px;
  }

  &__title {
    font-size: 3rem;
    margin-bottom: 3rem;
    text-align: center;
  }

  &__content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 4rem;

    @include tablet() {
      grid-template-columns: 1fr;
    }

    @include mobile() {
      grid-template-columns: 1fr;
      gap: 2rem;
    }

    &__item {
      max-width: 100%;
    }
  }

  &__description {
    margin-bottom: 3rem;
    font-size: 1.5rem;

    @include tablet() {
      text-align: left;
    }
    @include mobile() {
      text-align: left;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    margin-bottom: 3rem;

    li {
      display: flex;
      gap: 1rem;
    }

    a {
      width: fit-content;
    }
  }

  &__button {
    display: flex;
    align-items: center;
    width: fit-content;
    margin-left: auto;

    &:hover {
      svg {
        @include bounceRight(1s);
      }
    }

    @include tablet() {
      margin-left: 0;
    }
    @include mobile() {
      margin-left: 0;
    }

    a {
      display: flex;
      flex-direction: column;
      width: fit-content;
      margin-left: auto;
    }

    svg {
      margin-left: 0.5rem;
      margin-bottom: 0.2rem;
    }
  }
}
