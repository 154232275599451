.about-me-page {
  padding: 5rem 10% 3rem;
  color: #ffffff;
  text-align: justify;
  max-width: 1440px;
  width: 100%;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  margin: auto;

  // Add responsive styles for smaller devices
  @include tablet() {
    padding: 5rem 5% 2rem;
  }

  @include mobile() {
    padding: 5rem 2% 1rem;
  }

  &__title {
    font-size: 3rem;
    margin-bottom: 3rem;
  }

  &__content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 4rem;
  }

  &__description {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  &__images {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    justify-content: space-between;

    .image {
      max-width: 100%;
      max-height: 28rem;
      position: relative;
      z-index: 1;
      box-shadow: -1rem 1rem 2rem rgba(0, 0, 0, 0.2);
      border-radius: 1rem;

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        right: 0;
        bottom: 0;
        border: 2rem solid $color-primary-dark;
        border-radius: inherit;
        width: calc(100% - 2rem);
        z-index: -1;
      }

      &:first-child::after {
        transform: translateX(-50%) rotate(2deg);
      }
      &:nth-child(2)::after {
        transform: translateX(-50%) rotate(-5deg);
      }
      &:nth-child(3)::after {
        transform: translateX(-50%) rotate(4deg);
      }

      img {
        margin: auto;
        max-width: 100%;
        max-height: 100%;
        border-radius: 1rem;
      }
    }
  }
}
