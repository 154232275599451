.about-and-skills,
.contact-info {
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100vw;
    height: 100%;
    z-index: -1;
    pointer-events: none;
  }
}

// light with dark accent
.about-and-skills::after {
  // background-image: linear-gradient(0deg, #648399 -30%, transparent);
  background: #648399;
}

.contact-info::after {
  // background-image: linear-gradient(133deg, #648399 75%, transparent 0%);
  background: #648399;
}
