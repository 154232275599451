.about-and-skills {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10rem;

  padding: 3rem 10%;
  max-width: 1440px;
  color: $color-warm-white;
  text-align: justify;
  width: 100%;
  position: relative;
  z-index: 1;

  .about-me {
    &__title {
      font-size: 3rem;
      margin-bottom: 3rem;
      width: fit-content;
      position: relative;
      z-index: 1;
    }

    &__content {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 4rem;

      @include tablet() {
        grid-template-columns: repeat(1, 1fr);
      }
      @include mobile() {
        grid-template-columns: repeat(1, 1fr);
        gap: 2rem;
      }

      p {
        font-size: 1.5rem;
        margin-bottom: 1.5rem;
      }
    }
  }

  .skills {
    &__title {
      font-size: 3rem;
      margin-bottom: 3rem;
    }

    &__list {
      display: flex;
      flex-direction: column;
      gap: 0.1rem;
    }

    &__item {
      display: flex;
      align-items: center;
      padding: 0.5rem;
      background-color: $color-primary;
      border-radius: 1rem;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      transition: background-color 0.1s;

      &:hover {
        background-color: $color-primary-dark-40;
      }
    }

    &__icon {
      display: flex;
      align-items: center;
      height: 100%;
      margin-right: 1rem;

      svg {
        height: 100%;
        width: auto;
      }
    }

    &__name {
      font-size: 1.5rem;
      pointer-events: none;
    }

    &__description {
      font-size: 1.5rem;
      margin-left: auto;
    }
  }
}
