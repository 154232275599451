.experiences {
  padding: 3rem 10%;
  color: $color-warm-white;
  max-width: 1440px;
  width: 100%;
  position: relative;
  z-index: 1;

  // Add responsive styles for smaller devices
  @include tablet() {
    padding: 2rem 50px;
  }

  @include mobile() {
    padding: 1rem 30px;
  }

  &__title {
    font-size: 3rem;
    margin-bottom: 3rem;
    text-align: center;
  }

  &__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 4rem;
    position: relative;

    @include tablet() {
      grid-template-columns: repeat(1, 1fr);
    }
    @include mobile() {
      grid-template-columns: repeat(1, 1fr);
      gap: 2rem;
    }
  }
}

.experience {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: $color-primary;
  border-radius: 1rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  position: relative;
  height: 10rem;

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: $color-primary;
    border-radius: 1rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    position: relative;

    &:hover {
      .experience__overlay {
        @include wipeTopRight(0.3s);
      }
    }
  }

  &__image-wrapper {
    height: 12rem;
    width: 100%;
    padding: 2rem 5rem;
  }

  &__image {
    height: 100%;
    width: 100%;
    object-fit: contain;
    filter: grayscale(1);
  }

  &__content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__title {
    font-size: 2rem;
    margin-bottom: 1rem;
    text-align: center;
  }

  &__description {
    font-size: 1.5rem;
    text-align: justify;
    padding: 2.5rem;
  }

  &__link {
    margin-top: 1rem;
    font-size: 1.5rem;
    color: $color-white;
    text-decoration: none;
    border-bottom: 1px solid $color-white;
    padding-bottom: 0.5rem;
    transition: all 0.3s;

    &:hover {
      color: $color-primary;
      border-bottom: 1px solid $color-primary;
    }
  }

  &__read-more {
    width: 100%;
    background-color: $color-primary-dark-60;
    padding: 1rem;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.3s;
    margin-top: auto;

    span {
      pointer-events: none;
    }

    // hide the default scrolling bar on hover
    &::after {
      visibility: hidden;
    }

    span {
      // create a scrolling underline on hover
      &::after {
        content: "";
        display: block;
        width: 0;
        height: 2px;
        background-color: $color-warm-white;
        transition: width 0.3s;
      }
    }

    &:hover {
      background-color: $color-primary-dark;

      span {
        // animate a scrolling underline on hover
        &::after {
          width: 100%;
        }
      }
    }

    &__icon {
      margin-right: 0.5rem;
    }
  }

  &__overlay {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    clip-path: polygon(0 0, 0 0, 0 0, 0 0);

    &.active {
      @include wipeTopLeftFull(0.3s);
    }

    &__exit {
      position: absolute;
      top: 1rem;
      right: 1rem;
      font-size: 2rem;
      background-color: transparent;

      svg {
        fill: $color-warm-white;
      }
    }

    &__header {
      display: flex;
      width: 100%;
      padding: 2rem 5rem;
      align-items: center;
      flex-direction: column;
    }

    &__content {
      max-width: 1440px;
      background-color: $color-primary-dark-90;
      border-radius: 1rem;
      z-index: 1;
      overflow: scroll;
      position: relative;
      left: 50%;
      top: 50%;
      width: 80vw;
      height: 80vh;
      transform: translate(-50%, -50%);

      &::after {
        clip-path: polygon(0 0, 100% 0, 100% 80%, 0 100%);
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $color-primary-70;
        z-index: -1;
        border-radius: 1rem;
      }
    }

    &__image {
      height: 7rem;
      width: auto;
      filter: invert(1) grayscale(1);
    }

    &__title {
      font-size: 2rem;
      margin: 1rem 0;
    }

    &__description {
      font-size: 1.5rem;
      text-align: justify;
      padding: 0 2.5rem 2.5rem 2.5rem;
    }

    &__background {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100vw;
      height: 100vh;
      opacity: 0;
      z-index: -1;
    }
  }
}
